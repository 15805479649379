import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const ListingIntroModule = loadable(() =>
  import("../components/ListingIntroModule/ListingIntroModule")
)
const TeamsTab = loadable(() => import("../components/TeamsTab/TeamsTab"))

const InnerPagination = loadable(() =>
  import("../components/InnerPagination/InnerPagination")
)

const Teams = ({ data }, props) => {
  const PageData = data?.strapiPage
  const team = data?.allStrapiTeam.edges
  return (
    <Layout popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top">
        <BreadcrumbModule
          parentname={PageData?.choose_menu[0]?.strapi_parent?.title}
          parent={PageData?.choose_menu[0]?.strapi_parent?.slug}
          pagename={PageData?.title}
        />
        <div className="grey-bg">
          {PageData?.add_page_modules?.length > 0 &&
            PageData.add_page_modules?.map((module, index) => {
              return (
                <>
                  {module.strapi_component === "page-modules.listing-module" &&
                    module.choose_list === "teams" && (
                      <>
                        <ListingIntroModule
                          description={module.description}
                          headingTitle={
                            module.title ? module.title : PageData.title
                          }
                        />
                        <TeamsTab data={team} />
                        <InnerPagination />
                      </>
                    )}
                </>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    />
  )
}

export default Teams

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_LISTING_MODULE {
          ...ListingModuleFragment
        }
      }
    }
    allStrapiTeam(
      sort: { fields: rank, order: ASC }
      filter: { publish: { eq: true } }
    ) {
      edges {
        node {
          ...TeamFragment
          slug
        }
      }
    }
  }
`
